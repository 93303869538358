/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Banner from "../../components/Banner"
import Layout from "../../components/layout"
import arrow from "../../assets/images/icons/arrow.svg"
import earthBanner from "../../assets/images/technology/chantico/banner.svg"
import ChanticoAnimatingTitleSection from "../../components/technology/chantico/chantico-animating-title-section"
import IconBackground from "../../assets/icons/technology/icon-grid-background.svg"

// Animation Icons
import IconMobile from "../../assets/icons/technology/chantico/icon-animation-mobile.inline.svg"
import IconAnimation1 from "../../assets/icons/technology/chantico/icon-animation-1.inline.svg"
import IconAnimation2 from "../../assets/icons/technology/chantico/icon-animation-2.inline.svg"
import IconAnimation3 from "../../assets/icons/technology/chantico/icon-animation-3.inline.svg"
import IconAnimation4 from "../../assets/icons/technology/chantico/icon-animation-4.inline.svg"
import Tabs from "../../components/dep--tabs"

/* -------------------------------------------------------------------------- */
/*                            Functional Component                            */
/* -------------------------------------------------------------------------- */
export default function Chantico() {
    return (
        <Layout pageName="sustain-strategy ndb-dep ndb-dep--chantico">
            <Seo title="NDB Chantico" />
            <Banner width="fullwidth" pageName="sustain-strategy">
                <img src={earthBanner} className="banner__overlay" alt="banner background " />
                <div className="banner__data">
                    <div className="c-button">
                        <h2 className="banner__heading">NDB Chantico</h2>
                        <Link
                            to="/technology"
                            target="_blank"
                            className="c-button__link banner__button"
                        >
                            <img src={arrow} alt="buttonImg" className="c-button__img " />
                            <span className="c-button__name text-left">Technology</span>
                        </Link>
                    </div>
                </div>
            </Banner>

            <Tabs />

            <ChanticoAnimatingTitleSection />

            <div
                className="ndb-dep__animation-wrapper"
                style={{ backgroundImage: `url(${IconBackground})` }}
            >
                <div className="container">
                    <div className="animation-wrapper animation-wrapper--mobile">
                        <IconMobile />
                    </div>

                    <div className="animation-wrapper animation-wrapper--desktop">
                        <IconAnimation1 className="animation--1" data-aos="slide-up" />

                        <IconAnimation2 className="animation--2" data-aos="slide-up" />

                        <IconAnimation3 className="animation--3" data-aos="slide-up" />

                        <IconAnimation4 className="animation--4" data-aos="slide-up" />
                    </div>

                    <div className="animation__content">
                        <div className="sticky">
                            <div className="content__item" data-aos="fade-up">
                                <h3 className="mb-1 fw-600 text-uppercase">Next generation RTG</h3>
                                <p>
                                    A high efficiency radioisotope thermoelectric generator
                                    converting the heat energy generated by the decay of a
                                    radioisotope into electricity
                                </p>
                            </div>

                            <div className="content__item" data-aos="fade-up">
                                <h3 className="mb-1 fw-600 text-uppercase">Higher Efficiency</h3>
                                <p>
                                    New design improvements, advanced thermoelectric conversion
                                    mechanisms and advanced materials that are stretching the
                                    efficiency boundaries.
                                </p>
                            </div>

                            <div className="content__item" data-aos="fade-up">
                                <h3 className="mb-1 fw-600 text-uppercase">
                                    Nuclear waste recycling
                                </h3>
                                <p>
                                    A sustainable design targeting spent nuclear fuel elements or
                                    minor actinides and burning nuclear waste.
                                </p>
                            </div>

                            <div className="content__item" data-aos="fade-up">
                                <h3 className="mb-1 fw-600 text-uppercase">Co-generation</h3>
                                <p>
                                    A Watt to Kilowatts power and heating solution for space and
                                    communities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
